<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
          <ion-back-button color="primary"></ion-back-button>
        </ion-buttons>
        <ion-title>Modifica Account</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">Modifica Account</ion-title>
        </ion-toolbar>
      </ion-header>   
      <div id="container">
        <form @submit="submit">
          <label>Nome:</label>
          <ion-input id="input" v-model="account.name"></ion-input>              
          <label>Indirizzo visualizzato:</label>
            <ion-select id="input" value="1" v-model="indirizzo">
              <ion-select-option value="1">Indirizzo 1</ion-select-option>
              <ion-select-option value="2">Indirizzo 2</ion-select-option>
              <ion-select-option value="3">Indirizzo 3</ion-select-option>
            </ion-select>       
            <div v-if="indirizzo==1">
              <label>Indirizzo:</label>
              <ion-input id="input" v-model="account.address"></ion-input>
              <label>Città:</label>
              <ion-input id="input" v-model="account.city"></ion-input>
              <label>KM:</label>
              <ion-input id="input" v-model="account.km"></ion-input>
              <label>Pedaggio:</label>
              <ion-input id="input" v-model="account.travel_cost"></ion-input>
            </div>
            <div v-if="indirizzo==2">
              <label>Indirizzo:</label>
              <ion-input id="input" v-model="account.address2"></ion-input>
              <label>Città:</label>
              <ion-input id="input" v-model="account.city2"></ion-input>
              <label>KM:</label>
              <ion-input id="input" v-model="account.km2"></ion-input>
              <label>Pedaggio:</label>
              <ion-input id="input" v-model="account.travel_cost2"></ion-input>
            </div>
            <div v-if="indirizzo==3">
              <label>Indirizzo:</label>
              <ion-input id="input" v-model="account.address3"></ion-input>
              <label>Città:</label>
              <ion-input id="input" v-model="account.city3"></ion-input>
              <label>KM:</label>
              <ion-input id="input" v-model="account.km3"></ion-input>
              <label>Pedaggio:</label>
              <ion-input id="input" v-model="account.travel_cost3"></ion-input>
            </div>  
            <ion-button type="submit">MODIFICA</ion-button> 
          </form>
        </div>
    </ion-content>
  </ion-page>
</template>

<script>
  import { 
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent, 
    IonHeader,
    IonInput, 
    IonMenuButton, 
    IonPage,
    IonSelect,
    IonSelectOption,  
    IonTitle, 
    IonToolbar,
    toastController,
  } from '@ionic/vue';

  export default {
    name: 'Folder',
    components: {
      IonBackButton,
      IonButton,
      IonButtons,
      IonContent,
      IonHeader,
      IonInput, 
      IonMenuButton,
      IonPage,
      IonSelect,
      IonSelectOption, 
      IonTitle,
      IonToolbar
    },
    data: function(){
      return{
        account:{
          name: '',
          address:'',
          city:'',
          km:0,
          travel_cost:0,
          address2:'',
          city2:'',
          km2:0,
          travel_cost2:0,
          address3:'',
          city3:'',
          km3:0,
          travel_cost3:0,
        },
        indirizzo:0,
      }
    },
    methods:{
      submit(ev){
        ev.preventDefault();
        if(this.name!='' && this.address!=''){
          this.axios.post(this.apiUrl + 'update-account', {account:this.account}, this.headers)
          .then(async (response) => {
            if(response.data.status_code==200){
              const toast = await toastController
              .create({
                color: 'success',
                message: response.data.message,
                duration: 2000
              })
              this.$router.push('/folder/accounts-list');
              return toast.present();
            }
          })
        }
      },
      deleteAccount(){
        this.axios.post(this.apiUrl + 'delete-account', {account:this.account}, this.headers)
        .then(async (response) => {
          if(response.data.status_code==200){
            this.account=response.data.message              
          }
        })
      },
      getAccountInfo(){
        this.axios.post(this.apiUrl + 'get-account-info', {id:this.$route.params.id}, this.headers)
        .then(async (response) => {
          this.account=response.data.account;
        })
      }
    },
    ionViewWillEnter: function(){
      this.getAccountInfo();
    }
}
</script>
<style scoped>
  #input{
    background-color:#a9bdff48;
    border-radius:5px;
    margin:5px;
    width:90%;
  }
  form{
    padding:10px;
  }
</style>